import { Switch, Tab, Tabs } from '@mui/material';
import Dialog from '../dialogs/dialog/Dialog';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { EToastSeverity, NTextInput, Tooltip, useToastContextStateValue } from 'nulia-ui';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
    ContentWrapper,
    CountBox,
    CurrentLicenseNumberLabelTypography,
    CurrentLicenseNumberValueTypography,
    LicenseSyncLabel,
    NewLicenseNumberLabelTypography,
    NewLicenseNumberValueTypography,
    StyledForm,
    StyledSwitchFormControlLabel,
    StyledTextInputFormControlLabel,
    TabPanelRootBox
} from './Style';
import InfoIcon from '../../components/icons/InfoIcon';
import { useTranslation } from 'react-i18next';
import { useDeleteDisableLicenseSync, usePostEnableLicenseSync } from '../../services/AccountsQueryService';

interface IProps {
    isOpen: boolean;
    onCloseCallback: () => void;
    currentLicenseNumber?: number;
    customerId: string;
    isLicenseSyncEnabled: boolean;
    onChangeLicenseSyncCallback: (isEnabled: boolean) => void;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <TabPanelRootBox>{children}</TabPanelRootBox>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const UpdateLicenseCountModal: FC<IProps> = ({
    isOpen,
    onCloseCallback,
    currentLicenseNumber,
    customerId,
    isLicenseSyncEnabled,
    onChangeLicenseSyncCallback
}) => {
    const [activeTabValue, setActiveTabValue] = useState<number>(0);
    const formRef = useRef<HTMLFormElement>(null);
    const { t } = useTranslation();
    const { mutateAsync: enableLicenseSyncAsync, isPending: isEnableLicenseSyncPending } = usePostEnableLicenseSync();
    const { mutateAsync: disableLicenseSyncAsync, isPending: isDisableLicenseSyncPending } =
        useDeleteDisableLicenseSync();
    const { setToastMessage } = useToastContextStateValue();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTabValue(newValue);
    };

    const handleConfirm = (event: React.SyntheticEvent) => {
        alert('TODO: Submit');
    };

    const changeLicenseSync = async (isChecked: boolean) => {
        if (isChecked) {
            try {
                await enableLicenseSyncAsync({ customerId });
                setToastMessage({
                    isOpen: true,
                    message: t('successToastMessages.licenses.licenseSyncEnabled'),
                    severity: EToastSeverity.SUCCESS
                });
                if (onChangeLicenseSyncCallback) onChangeLicenseSyncCallback(isChecked);
            } catch (err) {
                setToastMessage({
                    isOpen: true,
                    message: t('errorToastMessages.licenses.licenseSyncEnabled'),
                    severity: EToastSeverity.ERROR
                });
            }
        } else {
            try {
                await disableLicenseSyncAsync({ customerId });
                setToastMessage({
                    isOpen: true,
                    message: t('successToastMessages.licenses.licenseSyncDisabled'),
                    severity: EToastSeverity.SUCCESS
                });
                if (onChangeLicenseSyncCallback) onChangeLicenseSyncCallback(isChecked);
            } catch (err) {
                setToastMessage({
                    isOpen: true,
                    message: t('errorToastMessages.licenses.licenseSyncDisabled'),
                    severity: EToastSeverity.ERROR
                });
            }
        }
    };

    const {
        control,
        handleSubmit,
        formState: { isValid, isSubmitting, errors },
        setValue,
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            sync: isLicenseSyncEnabled,
            numberOfLicenses: 0
        }
    });
    const numberLicensesWatch = useWatch({ name: 'numberOfLicenses', control });

    useEffect(() => {
        setValue('sync', isLicenseSyncEnabled);
        setValue('numberOfLicenses', 0);
        reset({
            sync: isLicenseSyncEnabled
        });
    }, [activeTabValue, isLicenseSyncEnabled]);

    const handleUpdateLicenseCount = () => {
        alert('ON submit');
    };

    const tabContentMemo = useMemo(() => {
        if (currentLicenseNumber === undefined) return <></>;
        return (
            <StyledForm onSubmit={handleSubmit(handleUpdateLicenseCount)} ref={formRef}>
                {activeTabValue === 0 && (
                    <Controller
                        name='sync'
                        control={control}
                        disabled={isEnableLicenseSyncPending || isDisableLicenseSyncPending}
                        render={({ field }) => {
                            return (
                                <StyledSwitchFormControlLabel
                                    label={
                                        <LicenseSyncLabel>
                                            License Sync
                                            <Tooltip title={t('updateLicenseCount.licenseSyncTooltip')}>
                                                <InfoIcon />
                                            </Tooltip>
                                        </LicenseSyncLabel>
                                    }
                                    labelPlacement='start'
                                    disabled={isEnableLicenseSyncPending || isDisableLicenseSyncPending}
                                    control={
                                        <Switch
                                            color='secondary'
                                            // defaultChecked={field.value}
                                            checked={field.value}
                                            onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                setValue('sync', checked);
                                                changeLicenseSync(checked);
                                            }}
                                            aria-label='Switch to Master Level'
                                            inputProps={{ 'aria-label': 'Switch to' }}
                                        />
                                    }
                                />
                            );
                        }}
                    />
                )}
                <Controller
                    name={'numberOfLicenses'}
                    control={control}
                    rules={{
                        required: true,
                        max: {
                            value: activeTabValue === 0 ? 500000 : currentLicenseNumber!,
                            message:
                                activeTabValue === 0
                                    ? 'Maximum number of licenses is 500000'
                                    : `You can remove maximum of ${currentLicenseNumber} licenses`
                        },
                        min: 0
                    }}
                    render={({ field }) => {
                        return (
                            <StyledTextInputFormControlLabel
                                label='Number of Licenses'
                                labelPlacement='end'
                                control={
                                    <NTextInput
                                        type='number'
                                        label=''
                                        field={field}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['numberOfLicenses']?.message as string}
                                        boxStyle={{ flex: 2 }}
                                    />
                                }
                            />
                        );
                    }}
                />
                {
                    <>
                        <CountBox>
                            <CurrentLicenseNumberLabelTypography>
                                Current licence number:
                            </CurrentLicenseNumberLabelTypography>
                            <CurrentLicenseNumberValueTypography>
                                {currentLicenseNumber || 0}
                            </CurrentLicenseNumberValueTypography>
                        </CountBox>
                        <CountBox>
                            <NewLicenseNumberLabelTypography>
                                New licence number after submitting:
                            </NewLicenseNumberLabelTypography>
                            <NewLicenseNumberValueTypography>
                                {activeTabValue == 0 && !isNaN(numberLicensesWatch)
                                    ? //@ts-ignore
                                      (currentLicenseNumber || 0) + parseInt(numberLicensesWatch || 0)
                                    : //@ts-ignore
                                      (currentLicenseNumber || 0) - parseInt(numberLicensesWatch || 0)}
                            </NewLicenseNumberValueTypography>
                        </CountBox>
                    </>
                }
            </StyledForm>
        );
    }, [
        activeTabValue,
        errors,
        isValid,
        numberLicensesWatch,
        currentLicenseNumber,
        isLicenseSyncEnabled,
        isEnableLicenseSyncPending,
        isDisableLicenseSyncPending,
        control
    ]);

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseCallback}
            content={
                <ContentWrapper>
                    <Tabs value={activeTabValue} onChange={handleChange}>
                        <Tab label='Add Licenses' {...a11yProps(0)} />
                        <Tab label='Remove Licenses' {...a11yProps(1)} />
                    </Tabs>
                    <CustomTabPanel value={activeTabValue} index={0}>
                        {tabContentMemo}
                    </CustomTabPanel>
                    <CustomTabPanel value={activeTabValue} index={1}>
                        {tabContentMemo}
                    </CustomTabPanel>
                </ContentWrapper>
            }
            acceptButtonWidth={170}
            onAccept={handleConfirm}
            acceptText={activeTabValue === 0 ? 'Add Licenses' : 'Remove Licenses'}
            cancelText='Cancel'
            isSubmitActionDisabled={isSubmitting || !isValid || numberLicensesWatch === 0}
            isBackdropCloseActive={false}
            width={500}
            minHeight='400px'
        ></Dialog>
    );
};

export default UpdateLicenseCountModal;
