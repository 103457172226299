import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { EApiQueryKey } from '../types/enums/EApiQueryKey';
import { ILicenseSummaryVM } from '../interfaces/views/ILicenseSummaryVM';
import { apiInstance } from './util/apiInstance';
import { mapLicenseSummary, mapPartners } from './mappers';
import { IPartnerVM } from '../interfaces/views/IPartnerVM';
import { IAddNewPartnerRequestDTO } from '../interfaces/dtos/IAddNewPartnerRequestDTO';
import { IPartnerUserVM } from '../interfaces/views/IPartnerUserVM';

// GET LICENSE SUMMARY
export const getLicenseSummary = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`licenseSummary`, headers);
    return mapLicenseSummary(data);
};

export const useGetLicenseSummaryQuery: () => UseQueryResult<ILicenseSummaryVM, Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.PARTNER_FETCH_LICENSE_SUMMARY],
        queryFn: async () => {
            return getLicenseSummary(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// GET PARTNERS
export const getPartners = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get('', headers);
    return mapPartners(data);
};

export const useGetPartnersQuery: () => UseQueryResult<IPartnerVM, Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.PARTNERS_FETCH_ALL],
        queryFn: async () => {
            return getPartners(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// POST ADD NEW PARTNER
export const postAddNewPartner: (
    variables: IAddNewPartnerRequestDTO & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, ...addNewPartnerRequestData } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    await apiInstance.post(``, addNewPartnerRequestData, headers);
    return undefined;
};

export const usePostAddNewPartner: () => UseMutationResult<undefined, Error, IAddNewPartnerRequestDTO> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IAddNewPartnerRequestDTO) => {
            return postAddNewPartner({ ...variables, authParams });
        }
    });
};

// GET PARTNERS USERS
export const getPartnersUsers = async (authParams: IAuthParams, partnerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`${partnerId}/user`, headers);
    return data;
};

export const useGetPartnersUsersQuery: (partnerId: string | undefined) => UseQueryResult<IPartnerUserVM, Error> = (
    partnerId
) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.PARTNER_USERS_FETCH_ALL, partnerId],
        queryFn: async () => {
            return getPartnersUsers(authParams, partnerId);
        },
        refetchOnWindowFocus: false
    });
};
