import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { apiInstance } from './util/apiInstance';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { EApiQueryKey } from '../types/enums/EApiQueryKey';
import { mapFolderLicenseSummary } from './mappers';
import { ILicenseSummaryVM } from '../interfaces/views/ILicenseSummaryVM';

// GET FOLDER LICENSE SUMMARY
export const getFolderLicenseSummary = async (authParams: IAuthParams, folderId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`folder/${folderId}/licenseSummary`, headers);
    return mapFolderLicenseSummary(data);
};

export const useGetFolderLicenseSummaryQuery: (
    folderId: string | undefined
) => UseQueryResult<ILicenseSummaryVM, Error> = (folderId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.FOLDER_LICENSE_SUMMARY_FETCH, folderId],
        queryFn: async () => {
            return getFolderLicenseSummary(authParams, folderId);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};
