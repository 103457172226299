import { FC, useCallback, useState } from 'react';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
import Connection from './connection/Connection';
import { RootBox } from './Style';
import { EConnectionStatus } from '../../interfaces/enums/EConnectionStatus';
import { EAccountType } from '../../interfaces/enums/EAccountType';
import Administrators from './administrators/Administrators';
import Activity from './activity/Activity';
import { useAccountDetailsStateValue } from '../../contexts/AccountDetailsContext';
import Settings from './settings/Settings';

const AccountDetails: FC = () => {
    const [connectionStatus, setConnectionStatus] = useState<EConnectionStatus>(EConnectionStatus.ACTIVE);
    const {
        activityData,
        administratorsList,
        customerId,
        isAdministratorsLoading,
        isAdministratorsError,
        refetchAdministratorsApiCall,
        settingsData,
        isSettingsLoading,
        isSettingsError,
        refetchSettingsApiCall
    } = useAccountDetailsStateValue();

    const changeConnectionStatus = useCallback((newConnectionStatus: EConnectionStatus) => {
        setConnectionStatus(newConnectionStatus);
    }, []);

    return (
        <Page>
            <PageContent>
                <RootBox>
                    <Connection
                        connectionStatus={connectionStatus}
                        changeConnectionStatus={changeConnectionStatus}
                        defaultExpanded
                        accountType={EAccountType.CUSTOMER}
                    />
                    <Administrators
                        customerId={customerId}
                        administratorsList={administratorsList}
                        isError={isAdministratorsError}
                        isLoading={isAdministratorsLoading}
                        refetch={refetchAdministratorsApiCall}
                    />
                    <Settings
                        settings={settingsData}
                        isError={isSettingsError}
                        isLoading={isSettingsLoading}
                        refetch={refetchSettingsApiCall}
                    />
                    <Activity data={activityData} />
                </RootBox>
            </PageContent>
        </Page>
    );
};

export default AccountDetails;
