import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { EApiQueryKey } from '../types/enums/EApiQueryKey';
import { apiInstance } from './util/apiInstance';
import { mapAdministrators, mapCustomers, mapCustomerSettings, mapResellers } from './mappers';
import { IAccountDetailsVM } from '../interfaces/views/IAccountDetailsVM';
import { IPostAddResellerDTO } from '../interfaces/dtos/IPostAddResellerDTO';
import { IResellerVM } from '../interfaces/views/IResellerVM';
import { ICustomerVM } from '../interfaces/views/ICustomerVM';
import { IPostAddCustomerDTO } from 'interfaces/dtos/IPostAddCustomerDTO';
import { IAdministratorVM } from 'interfaces/views/IAdministratorVM';
import { ICustomerSettingsVM } from 'interfaces/views/ICustomerSettingsVM';
import { ICustomerSettingsDTO } from 'interfaces/dtos/ICustomerSettingsDTO';

// GET ACCOUNT DETAILS
export const getAccountDetails = async (authParams: IAuthParams) => {
    // const { headers } = await getTokenAndHeaders(authParams);
    // const { data } = await apiInstance.get(`accounts`, headers);
    return [];
};

export const useGetAccountDetailsQuery: () => UseQueryResult<IAccountDetailsVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_DETAILS_FETCH],
        queryFn: async () => {
            return getAccountDetails(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// POST ADD RESELLER
export const postAddReseller: (
    variables: IPostAddResellerDTO & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, ...addResellerRequestData } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`folder`, addResellerRequestData, headers);
    return data;
};

export const usePostAddReseller: () => UseMutationResult<undefined, Error, IPostAddResellerDTO> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostAddResellerDTO) => {
            return postAddReseller({ ...variables, authParams });
        }
    });
};

// GET PARTNER RESELLERS
export const getPartnerResellers = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: {
            data: { folders }
        }
    } = await apiInstance.get(`folder`, headers);
    return mapResellers(folders);
};

export const useGetPartnerResellersQuery: () => UseQueryResult<IResellerVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_FETCH_RESELLERS],
        queryFn: async () => {
            return getPartnerResellers(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// GET PARTNER CUSTOMER
export const getPartnerCustomers = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: {
            data: { customers }
        }
    } = await apiInstance.get(`customer`, headers);
    return mapCustomers(customers);
};

export const useGetPartnerCustomersQuery: () => UseQueryResult<ICustomerVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_FETCH_CUSTOMERS],
        queryFn: async () => {
            return getPartnerCustomers(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// POST ADD CUSTOMER
export const postAddCustomer: (
    variables: IPostAddCustomerDTO & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, ...addCustomerRequestData } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`customer`, addCustomerRequestData, headers);
    return data;
};

export const usePostAddCustomer: () => UseMutationResult<undefined, Error, IPostAddCustomerDTO> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostAddCustomerDTO) => {
            return postAddCustomer({ ...variables, authParams });
        }
    });
};

// GET PARTNER CUSTOMER ADMINISTRATORS
export const getCustomerAdministrators = async (authParams: IAuthParams, customerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`customer/${customerId}/administrator`, headers);
    return mapAdministrators(data);
};

export const useGetCustomerAdministratorsQuery: (
    customerId: string | undefined
) => UseQueryResult<IAdministratorVM[], Error> = (customerId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.CUSTOMER_ADMINISTRATORS_FETCH, customerId],
        queryFn: async () => {
            return getCustomerAdministrators(authParams, customerId);
        },
        refetchOnWindowFocus: false
    });
};

// POST ENABLE LICENSE SYNC
export const postEnableLicenseSync: (
    authParams: IAuthParams,
    customerId: string | undefined
) => Promise<undefined> = async (authParams, customerId) => {
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`customer/${customerId}/licenseSync`, undefined, headers);
    return data;
};

export const usePostEnableLicenseSync: () => UseMutationResult<undefined, Error, { customerId: string }> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: { customerId: string }) => {
            return postEnableLicenseSync(authParams, variables.customerId);
        }
    });
};

// DELETE DISABLE LICENSE SYNC
export const deleteDisableLicenseSync: (
    authParams: IAuthParams,
    customerId: string | undefined
) => Promise<undefined> = async (authParams, customerId) => {
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.delete(`customer/${customerId}/licenseSync`, headers);
    return data;
};

export const useDeleteDisableLicenseSync: () => UseMutationResult<undefined, Error, { customerId: string }> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables) => {
            return deleteDisableLicenseSync(authParams, variables.customerId);
        }
    });
};

// GET CUSTOMER SETTINGS
export const getCustomerSettings = async (authParams: IAuthParams, customerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`customer/${customerId}/settings`, headers);
    return mapCustomerSettings(data);
};

export const useGetCustomerSettingsQuery: (
    customerId: string | undefined
) => UseQueryResult<ICustomerSettingsVM, Error> = (customerId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.CUSTOMER_SETTINGS_FETCH, customerId],
        queryFn: async () => {
            return getCustomerSettings(authParams, customerId);
        },
        refetchOnWindowFocus: false
    });
};

// POST CUSTOMER SETTINGS
export const postCustomerSettings: (
    variables: { customerId: string; customerSettings: ICustomerSettingsDTO } & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { headers } = await getTokenAndHeaders(variables.authParams);

    const {
        data: { data }
    } = await apiInstance.post(`customer/${variables.customerId}/settings`, variables.customerSettings, headers);
    return data;
};

export const usePostCustomerSettings: () => UseMutationResult<any, Error, any> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: { customerId: string; customerSettings: ICustomerSettingsDTO }) => {
            return postCustomerSettings({ ...variables, authParams });
        }
    });
};
