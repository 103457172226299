import { FC } from 'react';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
import AddPartner from './components/AddPartner';
import AddPartnerUser from './components/AddPartnerUser';
import { PartnersContentBox, TableCard } from './Style';
import PartnersTable from './table/PartnersTable';
import { IPartnerVM } from '../../interfaces/views/IPartnerVM';
import { EPartnerType } from '../../interfaces/enums/EPartnerType';

const Partners: FC = () => {
    const data: IPartnerVM[] = [
        {
            id: '1',
            status: EPartnerType.RESELLER,
            companyName: 'Nulia Services',
            nuliaLicenses: 30,
            officeLicenses: 22
        },
        {
            id: '2',
            status: EPartnerType.RESELLER,
            companyName: 'Nulia',
            nuliaLicenses: 100,
            officeLicenses: 45
        }
    ];
    return (
        <Page>
            <PageContent>
                <PartnersContentBox>
                    <AddPartner />
                    <AddPartnerUser />
                    <TableCard>
                        <PartnersTable isLoading={false} data={data} />
                    </TableCard>
                </PartnersContentBox>
            </PageContent>
        </Page>
    );
};

export default Partners;
