import BasicStatusCard, { EStatus } from '../../../components/basic-status-card/BasicStatusCard';
import { FC, useMemo, useState } from 'react';
import {
    AdministratorStatusTypography,
    ContentRootBox,
    DescriptionTypography,
    FormBox,
    FormColumnNameTypography,
    FormItemRow,
    HeaderTitleTypography,
    StyledForm
} from './Style';
import { Controller, useForm } from 'react-hook-form';
import { Loading, NTextInput, PrimaryButton, SomethingWentWrongCard } from 'nulia-ui';
import { IAdministratorVM } from '../../../interfaces/views/IAdministratorVM';
import { EAdministratorState } from '../../../interfaces/enums/EAdministratorState';
interface IProps {
    administratorsList?: IAdministratorVM[];
    customerId?: string;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
}

const Administrators: FC<IProps> = ({ administratorsList, customerId, isError, isLoading, refetch }) => {
    const [newAdministrators, setNewAdministrators] = useState<IAdministratorVM[]>([]);

    const { control } = useForm({
        mode: 'onChange'
    });

    const addNewAdministrator = () => {
        if (customerId) {
            const defaultAdministrator: IAdministratorVM = {
                customerId,
                name: '',
                email: '',
                state: EAdministratorState.ADDING,
                message: ''
            };
            setNewAdministrators((newAdministrators) => [...newAdministrators, defaultAdministrator]);
        }
    };

    const administratorsFormMemo = useMemo(() => {
        if (isLoading)
            return (
                <StyledForm>
                    <Loading />
                </StyledForm>
            );
        if (isError) {
            return (
                <StyledForm>
                    <SomethingWentWrongCard
                        boxStyle={{ boxShadow: 'none', minHeight: '140px' }}
                        actionCallback={refetch}
                    />
                </StyledForm>
            );
        }
        return (
            <>
                <FormColumnNameTypography variant='body2'>Name</FormColumnNameTypography>
                <FormColumnNameTypography variant='body2'>Email</FormColumnNameTypography>
                <FormColumnNameTypography variant='body2'>Status</FormColumnNameTypography>
                <StyledForm>
                    {[...(administratorsList || []), ...newAdministrators].map((administrator, index) => {
                        return (
                            <FormItemRow key={index}>
                                <Controller
                                    name={`name-${index}`}
                                    control={control}
                                    defaultValue={administrator.name}
                                    render={({ field }) => (
                                        <NTextInput
                                            onChange={field.onChange}
                                            value={field.value}
                                            label=''
                                            field={field}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`email-${index}`}
                                    control={control}
                                    defaultValue={administrator.email}
                                    render={({ field }) => (
                                        <NTextInput
                                            onChange={field.onChange}
                                            value={field.value}
                                            label=''
                                            field={field}
                                        />
                                    )}
                                />
                                <AdministratorStatusTypography variant='body2'>
                                    {administrator.state}
                                </AdministratorStatusTypography>
                            </FormItemRow>
                        );
                    })}
                </StyledForm>
            </>
        );
    }, [administratorsList, newAdministrators, control, isLoading, isError, refetch]);

    return (
        <BasicStatusCard defaultExpanded title='Administrators' status={EStatus.SUCCESS}>
            <ContentRootBox>
                <HeaderTitleTypography>
                    Connect your Office 365 Tenant to Fully Add Administrators
                </HeaderTitleTypography>
                <DescriptionTypography variant='body2'>
                    These people can bulk assign outcomes across the tenant.
                </DescriptionTypography>
                <FormBox>
                    {administratorsFormMemo}
                    <PrimaryButton
                        disabled={isError || isLoading}
                        clickHandler={addNewAdministrator}
                        title='Add Another'
                        style={{ width: '200px' }}
                    />
                </FormBox>
            </ContentRootBox>
        </BasicStatusCard>
    );
};

export default Administrators;
